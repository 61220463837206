
export default function FooterHome() {
    return(
        <div className="">
            <div className="bg-success text-white text-center rounded p-4 mt-5">
                <p>
                    © 2018-2024 Torreson -  Barranquilla-Atlantico, Colombia<br/>
                    {/* CL Falsa 123 - NIT: 890000006-1 - Código DANE: 08-000 - Código Postal 082001 - Conmutador +57 60000000<br/>*/}
                    {/*Condiciones de Uso de la Información - Quejas y Reclamos - Canales Físicos y Electrónicos<br/>*/}
                    {/*Directorio Institucional - Notificaciones Judiciales - Zona de Empleados<br/>*/}
                </p>
            </div>
        </div>
    )
}
