import InProgress from "../assets/inProgress.jpg"
import LayoutPageBody from "../components/LayoutPageBody";
export default function Gift() {
    return(

        <LayoutPageBody>
            <div className={'d-flex justify-content-center align-content-center'} style={{height:"80vh"}} >
                <img className={'img-fluid'} src={InProgress} alt={'en construccion'}/>
            </div>
        </LayoutPageBody>

    )
}