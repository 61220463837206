
import LayoutPageBody from "../components/LayoutPageBody";
import mision from '../assets/mision.png'
import vision from '../assets/vision.png'
import {Link} from "react-router-dom";
import React from "react";

export default function AboutUs() {
    return (
        <LayoutPageBody>
            <div className={'container-fluid'}>

                <div className="row justify-content-center">
                    <div className="col-12 mt-5">
                        <div className="card mb-3 border-0 shadow" style={{}}>
                            <div className="row g-0">
                                <div className="col-md-4">
                                    <img src={mision} className="img-fluid rounded-start" alt="..."/>
                                </div>
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <h5 className="card-title text-center pt-5">MISION</h5>
                                        <p className="card-text p-5 " style={{textAlign:"justify"}}>
                                            Ser parte de nuestra audiencia,
                                            brindando alegria musical al mundo!,
                                            que nuestro trabajo sirva además de entretener
                                            a mejorar el dia a dia nuestros oyentes dandoles ese toque de
                                            felicidad que necesitamos diariamente,
                                            con una actitud positiva
                                            y motivadora
                                            </p>
                                        <p className="card-text px-5 mt-5 p-3"><small className="text-muted">Última actualización 2022</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card mb-3 border-0 shadow" style={{}}>
                            <div className="row g-0">
                                <div className="col-md-4">
                                    <img src={vision} className="img-fluid rounded-start" alt="..."/>
                                </div>
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <h5 className="card-title text-center pt-5">VISION</h5>
                                        <p className="card-text p-5" style={{textAlign:"justify"}}>
                                            Para el año 2026 llegar a los más altos estándares de calidad en servicio a nuestra audiencia,
                                            con excelente sonido, fidelidad y ofreciendo información fresca, real, interesante, entregada con respeto y objetividad.
                                            Ser una herramienta para el aprendizaje con cápsulas culturales, de motivación y que la audiencia sea parte de la programación con su participación, ideas e inclusión en temas, propuestas y mejoras
                                            llegando cada dia a mas oyentes.
                                        </p>
                                        <p className="card-text px-5 mt-5 p-3"><small className="text-muted">Última actualización 2022</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </LayoutPageBody>
    )
}
