import React,{ useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
// import { UncontrolledCarousel } from 'reactstrap';
import slider1 from '../assets/wp4018835-radio-station-wallpapers.png'
import slider2 from '../assets/wp9591677-radio-station-wallpapers.jpg'
import slider3 from '../assets/wp9591679-radio-station-wallpapers.jpg'
import slider4 from '../assets/wp9591683-radio-station-wallpapers.jpg'
import slider5 from '../assets/wp9591680-radio-station-wallpapers.jpg'

const items = [
    {
        src: slider1,
        altText: 'Sonando',
        caption: 'Sonando Fuerte!',
        header: 'Torreson'
    },{
        src: slider2,
        altText: 'Sonando',
        caption: 'Sonando Fuerte!',
        header: 'Torreson'
    },{
        src: slider3,
        altText: 'Sonando',
        caption: 'Sonando Fuerte!',
        header: 'Torreson'
    },{
        src: slider4,
        altText: 'Sonando',
        caption: 'Sonando Fuerte!',
        header: 'Torreson'
    },{
        src: slider5,
        altText: 'Sonando',
        caption: 'Sonando Fuerte!',
        header: 'Torreson'
    },
];
export default function SliderInfo() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex:any) => {
        setIndex(selectedIndex);
    };


    return(
        <div>
       
            <Carousel activeIndex={index} onSelect={handleSelect}>
                {items.map((res,index)=>(
                    <Carousel.Item key={index}>
                        <img src={res.src} alt={res.altText} style={{width:'100vw'}} />
                        <Carousel.Caption>
                            <h3>{res.header}</h3>
                            <p>{res.caption}.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))

                }


            </Carousel>
        </div>

    )
}




