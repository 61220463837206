
interface Iprops{
    children:JSX.Element
}

export default function HeaderHome({children}:Iprops) {
    return(
        <header>
            {children}
        </header>
    )
}