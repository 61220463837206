import {Link} from "react-router-dom";

interface Iprops{
    news:News;
}
export type News= {
    id?:string;
    title: string;
    image: string;
    description: string;
    dateUpdate?: string;
}
export const NewsInitialState={
    dateUpdate:"", description:"", title:"", image:"",id:""
}

export default function NewsItem({news}: Iprops) {
    const {dateUpdate, description, title, image,id}=news;

    return (

            <div className="card mb-4">
                <div className="card-header  border-bottom-0">
                    <h5 className="card-title">{title}</h5>
                </div>
                <img src={image} className="card-img-bottom" alt={title}/>
                <div className="card-body">

                    <p className="card-text">{description.slice(0, 100)}</p>
                    <p className="card-text"><small className="text-muted">Última actualización {dateUpdate}</small>
                    </p>
                    <Link to={`/news/${id}`}>ver mas...</Link>

                </div>

            </div>
    )
}