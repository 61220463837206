import {NavLink} from "react-router-dom";
import logo from "../assets/logo.png"
import style from "../styles/scss/styles.module.scss"
import {useState} from "react";
export default function MenuHome() {
const [open, setOpen]=useState(false);
    return (
        <>
            <div className={` row bg-success mx-0 ${open && style.sidebarOpen}`} style={{height: "5.3em" , background: 'linear-gradient(0deg, rgba(192,13,3,1) 0%, rgba(152,10,4,1) 11%, rgba(107,7,6,1) 47%, rgba(136,9,5,1) 73%, rgba(192,13,3,1) 100%)'}}>
                    <div className=" col-12 col-lg-4 mx-2   ">
                    <div className="row">
                        <div className="col-2">
                            <svg
                                className={` d-md-none  d-inline-block ${style.hamburger}`}
                                onClick={() => setOpen(!open)}
                                viewBox="0 0 18 12"
                            >
                                <path
                                    d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z"
                                    fill="#8F8F8F"
                                />
                            </svg>
                        </div>
                        <div className="col-7">
                            <div className="text-center  text-lg-end">

                                <NavLink to={'/'} type="button">
                                    <img className="" style={{width: '18.3em'}} src={logo} alt={'logo'}/>
                                </NavLink>
                            </div>
                        </div>
                    </div>


                </div>
                <div className={` col-12 col-lg-7  text-center text-xl-start btn-group ${!open && 'd-none'}  d-md-block  ${open && style.linksContainer}`} style={{top: "-10px", zIndex: "3"}}
                     role="group" aria-label="Basic mixed styles example">

                    <NavLink to={'/'} className="btn btn-light rounded-top p-sm-4 text-white ">Home</NavLink>
                    {/*<NavLink to={'/services'}*/}
                    {/*         className="btn btn-light rounded-top p-sm-4 text-white ">Servicios</NavLink>*/}
                    {/*<NavLink to={'/top'}*/}
                    {/*         className="btn btn-light rounded-top p-sm-4 text-white">Top mas Sonadas</NavLink>*/}
                    <NavLink to={'/gift'}
                             className="btn btn-light rounded-top p-sm-4 text-white">Premios</NavLink>
                    <NavLink to={'/news'}
                             className="btn btn-light rounded-top p-sm-4 text-white">El salon de Naty Velencia</NavLink>
                    <NavLink to={'/about-us'}
                             className="btn btn-light rounded-top p-sm-4 text-white">Nosotros</NavLink>


                </div>
            </div>
        </>
    )
}
