import React, {createContext, useEffect, useState} from "react";
import {News} from "../components/NewsItem";
import {collection, getDocs, getFirestore} from "firebase/firestore";
import firebaseApp from "../firebase";

const NewsContext = createContext<News[]>([]);
interface IProps {
    children: JSX.Element;
}
function NewsProvider({children}:IProps){
    // const [loading, setLoading]=useState(true);
    const [post, setPost]=useState<Array<News>>([])
    const db = getFirestore(firebaseApp)
    console.log('entra')
    useEffect(()=>{
        const getNews  = async () =>{
            try {
                let data:any[]=[];
                // const resultQuery:any= await getDocs(collection(db,'post'))
                const querySnapshot = await getDocs(collection(db,'post'));
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    data.push({...doc.data(),id:doc.id})
                });
                setPost(data);
                console.log('data', data)
            }catch (e) {
                console.log(e)
            }

        }
        getNews();
    },[])
    // @ts-ignore
    return(
        <NewsContext.Provider value={post}>
            {children}
        </NewsContext.Provider>
    )
}

export {NewsContext,NewsProvider};