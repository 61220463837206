// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage"
import {getAnalytics} from "firebase/analytics";
import {v4} from "uuid"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDL6n783j8Brxk7ndQ5_Z0SHo10Wi8gayM",
    authDomain: "torreson-a33ae.firebaseapp.com",
    projectId: "torreson-a33ae",
    storageBucket: "torreson-a33ae.appspot.com",
    messagingSenderId: "421421068728",
    appId: "1:421421068728:web:a442ee94fd4b1b30549f4d",
    measurementId: "G-6XLTR2M3B8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app)
const analytics = getAnalytics(app);

export async function uploadFile(file: any) {
    const storageRef = ref(storage, v4());
    await uploadBytes(storageRef, file)
    const result = await getDownloadURL(storageRef);
    return result;
}

export default app;