import FormPost from "../components/formPost";
import LayoutPageBody from "../components/LayoutPageBody";

export default function CreatePost() {
    return (
        <>
            <LayoutPageBody>
                <div className={'d-flex justify-content-center my-5'}>
                    <div className="card text-center" style={{width: '35rem'}}>
                        <div className="card-header">
                            Crear nuevo post
                        </div>
                        <div className="card-body ">
                            <FormPost/>
                        </div>
                        <div className="card-footer text-muted">
                            Este post se visualizara en la pagina principal de torreson
                        </div>
                    </div>
                </div>
            </LayoutPageBody>
        </>
    )
}