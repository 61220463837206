import pse from '../assets/pagos_pse.png'
import pse2 from '../assets/pagos_pse2.png'
import pse3 from '../assets/pagos_pse3.png'
import LogoRunt from '../assets/logoPublicidad.png'
import '../styles/img.css'
import {useEffect, useState} from "react";
let positionActual=0;
const arrayPse=[pse,pse2,pse3]

export default function SidebarInfo() {

    const [imgPse,setImgPse]=useState(pse)

    useEffect(()=>{


            setTimeout(() => pasarFoto(), 10000)

        function pasarFoto() {



            if(positionActual >= arrayPse.length - 1 ) {
                positionActual = 0;

            } else {
                positionActual++;
            }
            setImgPse(arrayPse[positionActual]);

        }

    },[imgPse])

    return(
        <div className="mb-4">

            <a href={'/#'}>
                <div >
                    <img src={LogoRunt}className="img-fluid border border-2 mb-2  rounded rounded-4" alt={'logo Runt'}/>
                </div>
            </a><a href={'/#'}>
                <div >
                    <img src={LogoRunt}className="img-fluid border border-2 mb-2  rounded rounded-4" alt={'logo Runt'}/>
                </div>
            </a><div className="bg-light mb-2 p-5 rounded text-white"><h2>publicidad</h2></div>
            <div className="bg-light mb-2 p-5 rounded text-white"><h2>publicidad</h2></div>
            <div className="bg-light mb-2 p-5 rounded text-white"><h2>publicidad</h2></div>




        </div>
    )
}
