import LayoutPageBody from "../components/LayoutPageBody";
import NewsItem, {News, NewsInitialState} from "../components/NewsItem";
import React, {useContext, useEffect, useState} from "react";
import {NewsContext} from "../NewsContext";
import {useParams} from "react-router-dom";

export default function NewsPage(){
    const post=useContext(NewsContext);
    const [news,setNews]=useState<News>(NewsInitialState);
    let { id } = useParams();
    useEffect(()=>{
       const newsSelect = post.find(news=>news.id===id)
        if(newsSelect){
            setNews(newsSelect);
        }
    },[post,id])
    const {dateUpdate, description, title, image}=news;

    return(
        <LayoutPageBody>
            <>
                <div className={'row justify-content-center'}>
                    <div className={'col-8 m-2 mt-4'}>
                        <div className="card mb-4">
                            <div className="card-header  border-bottom-0">
                                <h5 className="card-title">{title}</h5>
                            </div>
                            <img src={image} className="card-img-bottom" alt={title}/>
                            <div className="card-body">
                                <p className="card-text">{description}</p>
                                <p className="card-text"><small className="text-muted">Última actualización {dateUpdate}</small>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={'col-3 m-4'}>
                        <div className={'card overflow-auto'} style={{height:'95vh'}}>
                            {post.map((news,index)=> <NewsItem key={index} news={news}/>)}
                        </div>
                    </div>
                </div>
            </>
        </LayoutPageBody>
    )
}