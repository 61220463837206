import HeaderHome from "./HeaderHome";
import MenuHome from "./MenuHome";
import FooterHome from "./FooterHome";
import React from "react";


interface Iprops {
    children: JSX.Element
}

export default function LayoutPageBody({children}: Iprops) {
    return (
        <>
            <HeaderHome>
                <>

                    <MenuHome/>

                </>
            </HeaderHome>
            <div className="">
                {children}
            </div >
            <FooterHome/>
        </>
    )
}
