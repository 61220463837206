import ReactAudioPlayer from "react-audio-player";
import React, {useEffect, useState} from "react";
import LayoutPageBody from "../components/LayoutPageBody";
import lp from "../assets/lp.png"
import {News} from "../components/NewsItem";
import {collection, getDocs, getFirestore} from "firebase/firestore";
import firebaseApp from "../firebase";
export default function Top() {
    const [styleLp,setStyleLp]=useState({id:0,action:""});
    const [tops, setTops]=useState<Array<any>>([])
    const db = getFirestore(firebaseApp)

    useEffect(()=>{
        const getTop  = async () =>{
            try {
                let data:any[]=[];
                const querySnapshot = await getDocs(collection(db,'top'));
                querySnapshot.forEach((doc) => {
                    data.push({...doc.data(),id:doc.id})
                });
                setTops(data);
            }catch (e) {
                console.log(e)
            }
        }
        getTop();
    },[]);

    return(

            <>
                <h3 className={'display-4 text-center my-5'} style={{fontSize:20, fontWeight:"bold"}}> Top 5 mas sonadas en torreson</h3>
                {tops.map((top,index) => (
                    <div key={index} className={' row text-center align-items-center'}>
                        <div className={'col-3 m-1'}>
                            <img className={`img-fluid ${index===styleLp.id&&styleLp.action}`} src={lp} alt={'lp'}/>

                        </div>
                        <div className={'col-8'}>
                            <ReactAudioPlayer
                                id={`${index}`}
                                style={{width: '100%'}}
                                src={top.son}
                                controls

                                onPlay={(e:any) => {


                                    setStyleLp({id: index, action: 'imagen'})
                                    // return   e.target.id===index&&
                                }}

                                onPause={(e:any) => {
                                    setStyleLp({id: index, action: ''})
                                }}
                            />
                        </div>
                    </div>
                ))}
            </>

    )
}

