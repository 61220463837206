import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import NotFount from "./pages/NotFount";
import Gift from "./pages/gift";
import CreatePost from "./pages/CreatePost";
import NewsPage from "./pages/news";

export default function App() {

    return(
        <BrowserRouter>
            <Routes>
                <Route path={"/"} element={<Home/>} />
                <Route path={"/gift"} element={<Gift/>} />
                <Route path={"/news/:id"} element={<NewsPage/>} />
                <Route path={"/about-us"} element={<AboutUs/>} />
                <Route path={"/create-post"} element={<CreatePost/>} />
                <Route path={"*"} element={<NotFount/>} />
            </Routes>
        </BrowserRouter>
    )
}


