import React from "react";

import ReactAudioPlayer from 'react-audio-player';



export default function Reproductor() {
    return(
        <div className={'mb-4 border rounded mx-2'} style={{background:'#f4f4f4'}}>
            <ReactAudioPlayer
                style={{width:'100%'}}

                src="https://play10.tikast.com/proxy/jeison12?mp=/1;"
                autoPlay={true}
                controls
            />

        </div>
    )
}
