import {getFirestore, addDoc, collection} from 'firebase/firestore'
import firebaseApp, {uploadFile} from '../firebase'
import {SubmitHandler, useForm} from "react-hook-form";

type Inputs = {
    title: string
    description: string
    image: any
}
export default function FormPost() {
    const db = getFirestore(firebaseApp)
    const {
        register,
        handleSubmit,
        watch,
        formState: {errors},
    } = useForm<Inputs>()
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        console.log(data)
       // const dataResult=
        try {
            const result =await uploadFile(data.image[0])
            await addDoc(collection(db, 'post'),
                {...data, image:result}
            )
        } catch (e) {
            console.log(e)
        }

    }


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                    <label  className="form-label">Titulo</label>
                    <input className="form-control" {...register("title", {required: true})} />
                    {errors.title && <span>This field is required</span>}
                </div>
                <div className="mb-3">
                    <label  className="form-label">Descripcion</label>
                    <textarea className="form-control" {...register("description", {required: true})} />
                    {errors.description && <span>This field is required</span>}

                </div>
                <div className="mb-3 ">
                    <label htmlFor="exampleInputEmail1" className="form-label">Imagen</label>
                    <input type={'file'} className="form-control"  {...register("image", {required: true})} />
                    {errors.image && <span>This field is required</span>}
                </div>
                <button type="submit" className={'btn btn-lg btn-secondary'}  >Guardar</button>
            </form>

        </>
    )
}