import React, {useContext} from 'react';
import '../App.css';
import LayoutPageBody from "../components/LayoutPageBody";
import SidebarInfo from "../components/SidebarInfo";
import SliderInfo from "../components/SliderInfo";
import Reproductor from "../components/Reproductor";
import NewsItem from "../components/NewsItem";
import Top from "./top";
import {Card} from "reactstrap";
import {NewsContext} from "../NewsContext";



function Home() {
    const post=useContext(NewsContext);
    return (
        <>
            <LayoutPageBody>
                <>
                    <SliderInfo/>
                    <Reproductor/>
                    <div className="container-fluid">
                        <div className="row  justify-content-center ">
                            <div className="col-12 col-md-3 ">
                                <Card className={'bg-secondary mb-3'}>
                                    <Top/>
                                </Card>

                                <SidebarInfo/>

                            </div>
                            <div className="col-12 col-md-9 ">
                                {/*{!loading && <Loading/>}*/}
                                {post.map((news,index)=> (index<2&&<NewsItem key={index} news={news}/>))                      }

                                {/*{<Timeline*/}

                                {/*    onLoad={()=> {*/}

                                {/*        setLoading(true)*/}
                                {/*    }}*/}
                                {/*    dataSource={{*/}
                                {/*        sourceType: 'profile',*/}
                                {/*        screenName: 'galapatransito'*/}
                                {/*    }}*/}
                                {/*    options={{*/}
                                {/*        height: '900'*/}
                                {/*    }}/>}*/}

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            {/*<Extension />*/}
                        </div>
                    </div>
                </>
            </LayoutPageBody>
        </>
    );
}

export default Home;
